<template>
    <basic-container>
        <avue-crud
            :option="option"
            :table-loading="loading"
            :data="data"
            :search.sync="search"
            :page.sync="page"
            :permission="permissionList"
            :before-open="beforeOpen"
            v-model="form"
            ref="crud"
            @row-update="rowUpdate"
            @row-save="rowSave"
            @row-del="rowDel"
            @search-change="searchChange"
            @search-reset="searchReset"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @refresh-change="refreshChange"
            @on-load="onLoad"
        >
            <template slot="menuLeft">
                <el-button v-if="!this.cardId" type="primary" size="small" icon="el-icon-back" plain @click="handleBack">返 回
                </el-button>
            </template>
            <template slot="cardIdSearch">
                <model-search-select
                    labelKey="cardNo"
                    valueKey="id"
                    v-model="search.cardId"
                    format="{cardNo}_{cardName}"
                    :getData="getVipCards"
                >
                    <template slot-scope="{ item }">
                        <span style="float: left"
                            >{{ item.cardNo
                            }}</span
                        >
                        <span style="float: right; color: #8492a6; font-size: 13px">{{
                            item.cardName
                        }}</span>
                    </template>
                </model-search-select>
            </template>
        </avue-crud>
    </basic-container>
</template>

<script>
import { getPage, getDetail, add, update, remove } from '@/api/spcod/spcodaccountdetail';
import { mapGetters } from 'vuex';
import {
    getPage as getVipPage,
} from '@/api/spcod/spcodaccountcard';

export default {
    data() {
        return {
            vipNo: this.$router.currentRoute.query.vipNo || null,
            queryAccId: this.$router.currentRoute.query.accId || null,
            form: {},
            search: {
                cardId: ''
            },
            query: {},
            loading: true,
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            selectionList: [],
            option: {
                height: this.cardId ? '300px' : 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                addBtn: true,
                index: true,
                viewBtn: true,
                selection: false,
                menu: false,
                dialogClickModal: false,
                column: [
                    {
                        label: '会员卡',
                        prop: 'cardId',
                        hide: true,
                        showColumn: false,
                        display: false,
                        search: this.cardId ? false : true
                    },
                    {
                        label: '会员号',
                        prop: 'vipNo',
                        width: 150,
                        rules: [
                            {
                                required: true,
                                message: '请输入会员ID',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '会员卡名称',
                        prop: 'cardName',
                        width: 150,
                        rules: [
                            {
                                required: true,
                                message: '请输入会员ID',
                                trigger: 'blur',
                            },
                        ],
                    },

                    {
                        label: '会员',
                        prop: 'vipName',
                        width: 150,
                        rules: [
                            {
                                required: true,
                                message: '请输入会员ID',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '金额',
                        prop: 'operValue',
                        rules: [
                            {
                                required: true,
                                message: '请输入金额',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '业务类型',
                        prop: 'bizType',
                        type: 'select',
                        search: this.cardId ? false : true,
                        dicData: [
                            {
                                label: '会议',
                                value: 'COD',
                            },
                            {
                                label: '文体',
                                value: 'SPT',
                            },
                            {
                                label: '餐食',
                                value: 'FOD',
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请输入业务类型，COD会议/SPT文体/FOD餐食',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '操作',
                        prop: 'operType',
                        type: 'select',
                        search: this.cardId ? false : true,
                        searchSpan: 5,
                        dicData: [
                            {
                                value: 1,
                                label: '充值',
                            },
                            {
                                value: 2,
                                label: '消费',
                            },
                            {
                                value: 3,
                                label: '退款',
                            },
                            {
                                value: 4,
                                label: '积分',
                            },
                            {
                                value: 5,
                                label: '5积分兑换',
                            },
                            {
                                value: 6,
                                label: '积分退回',
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请输入1充值、2消费、3退款、4积分、5积分兑换、6积分退回',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '操作时间',
                        prop: 'operTime',
                        width: 150,
                        rules: [
                            {
                                required: true,
                                message: '请输入操作时间',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '备注',
                        prop: 'memo',
                        hide: true,
                        rules: [
                            {
                                required: true,
                                message: '请输入备注',
                                trigger: 'blur',
                            },
                        ],
                    },
                ],
            },
            data: [],
        };
    },
    props: {
        cardId: {
            type: String,
            default: ''
        },
        accId: {
            type: String,
            default: ''
        }
    },
    mounted() {
        let _this = this;
        // _this.onLoad(_this.page);
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.spcodaccountdetail_add, false),
                viewBtn: this.vaildData(this.permission.spcodaccountdetail_view, false),
                delBtn: this.vaildData(this.permission.spcodaccountdetail_delete, false),
                editBtn: this.vaildData(this.permission.spcodaccountdetail_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    methods: {
        getVipCards(ev , done){
            getVipPage(1, 1000, {
                accId: this.accId || this.queryAccId
            }).then(res => {
                done(res.data.data.records);
            })
            // deptService.getDeptTree().then(res => {
            //     done(res.data.data || []);
            // })
        },
        rowSave(row, done, loading) {
            add(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    window.console.log(error);
                },
            );
        },
        rowUpdate(row, index, done, loading) {
            update(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    console.log(error);
                },
            );
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(row.id);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        handleBack() {
            this.$router.go(-1);
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning('请选择至少一条数据');
                return;
            }
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(this.ids);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.$refs.crud.toggleSelection();
                });
        },
        beforeOpen(done, type) {
            if (['edit', 'view'].includes(type)) {
                getDetail(this.form.id).then(res => {
                    this.form = res.data.data;
                });
            }
            done();
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            this.query = params;
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
            done();
        },
        selectionChange(list) {
            this.selectionList = list;
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        onLoad(page, params = {}) {
            this.loading = true;
            let query = {};
            if(this.vipNo){
                query['vipNo'] = this.vipNo;
            }
            if(this.cardId){
                query['cardId'] = this.cardId;
            }
            getPage(page.currentPage, page.pageSize, Object.assign(params, query)).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                this.loading = false;
                this.selectionClear();
            });
        },
    },
};
</script>

<style>
</style>
